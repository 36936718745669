<template>
  <v-container fluid>
    <img src="img/iq-white-bg-logo.png" id="logo" alt="logo" class="m-3" />
    <v-row class="page-wrapper h-100">
      <v-col
        cols="12"
        class="overflow-auto d-flex flex-column justify-content-between poppins container-wrapper-top h-100"
      >
        <div class="h-100 mt-lg-0 signin-container-wrapper">
          <div class="signin-container h-100">
            <v-row class="justify-content-center h-100">
              <v-col
                cols="8"
                md="4"
                lg="4"
                class="h-100 d-flex flex-column justify-content-between"
              >
                <div
                  class="flex-grow-1 d-flex align-items-center justify-content-center"
                >
                  <router-view></router-view>
                </div>
                <div class="text-white mb-3 text-center">
                  <div>
                    <img
                      src="/img/iqfulfillment-white-foreground copy.png"
                      class="w-140px mb-2 mr-2"
                    />
                    <span class="mx-1 font-size-lg mr-2">Powered by: </span>
                    <img
                      src="/img/IQ-Hybrid-white.png"
                      alt="logo"
                      class="img-fluid w-80px mr-2 mb-2"
                    />
                    <span class="font-size-h6"
                      >© {{ currentYear }} - All rights reserved.</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GeneratePasswordLayout",
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/auth/generate-password.scss";
</style>
